import ToastVX from '@/components/common/ToastVX';

let vxToastPlugin = {};
/*
  let settings = {
    title: '알림',
    resolveText: '닫기',
    resolve: ()=>{ do something... },
  }
  this.$vxAlert("입력이 올바르지 않습니다.");
  this.$vxAlert("입력이 올바르지 않습니다.", ()=>{ do something... });
  this.$vxAlert("입력이 올바르지 않습니다.", { ...settings } );

  let settings = {
    title: '확인',
    resolveText: '확인',
    rejectText: '취소',
    resolve: null,
    reject: null,
  };
  this.$vxConfirm("선택하신 데이터를 삭제하시겠습니까?", ()=>{ do something... });
  this.$vxConfirm("선택하신 데이터를 삭제하시겠습니까?", { ...settings } );
 */
vxToastPlugin.install = Vue => {
  var ToastConstructor = Vue.extend(ToastVX);
  var vxToast = null;

  function createToast() {
    if (vxToast == null) {
      vxToast = new ToastConstructor({
        el: document.createElement('div'),
        data() {
          return {
            view: false,
            warning: false,
          };
        },
      });
      document.body.appendChild(vxToast.$el);
    }
  }

  function info(message, options = {}) {
    if (typeof options === 'function') {
      options = { resolve: options };
    }
    // let settings = {
    //   title: '확인',
    //   resolveText: '확인',
    //   rejectText: '취소',
    //   resolve: null,
    //   reject: null,
    //   ...options,
    // };
    createToast();
    vxToast.type = 'info';
    vxToast.view = true;
    vxToast.message = message;
  }

  function success(message, options = {}) {
    if (typeof options === 'function') {
      options = { resolve: options };
    }
    // let settings = {
    //   title: '확인',
    //   resolveText: '확인',
    //   rejectText: '취소',
    //   resolve: null,
    //   reject: null,
    //   ...options,
    // };
    createToast();
    vxToast.type = 'success';
    vxToast.view = true;
    vxToast.message = message;
  }

  function error(message, options = {}) {
    if (typeof options === 'function') {
      options = { resolve: options };
    }
    // let settings = {
    //   title: '확인',
    //   resolveText: '확인',
    //   rejectText: '취소',
    //   resolve: null,
    //   reject: null,
    //   ...options,
    // };
    createToast();
    vxToast.type = 'error';
    vxToast.view = true;
    vxToast.message = message;
  }

  Vue.vxToastInfo = info;
  Vue.vxToastSuccess = success;
  Vue.vxToastError = error;
  Vue.prototype.$vxToastInfo = info;
  Vue.prototype.$vxToastSuccess = success;
  Vue.prototype.$vxToastError = error;
};

export default vxToastPlugin;
