<template>
  <div id="app">
    <router-view />
    <Loader :view="loadingStatus" :msg="msg"></Loader>
  </div>
</template>

<script>
import bus from '@/utils/bus.js';
import Loader from '@/components/common/Loader.vue';
export default {
  // eslint-disable-next-line
  name: 'App',
  components: { Loader },
  data() {
    return {
      msg: null,
      loadingStatus: false,
    };
  },
  methods: {
    startLoader(msg) {
      this.msg = msg;
      this.loadingStatus = true;
    },
    endLoader() {
      this.loadingStatus = false;
    },
  },
  created() {
    bus.$on('startLoader', this.startLoader);
    bus.$on('endLoader', this.endLoader);
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  /* sytv test */
  /* width: 1920px; */
  /* overflow: auto; */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
