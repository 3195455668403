import '@/assets/sass/mis.scss';

import '@/permission';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import moment from 'moment';
import VueMoment from 'vue-moment';
import OpenWindow from '@/components/common/OpenWindow.vue';
import GridVx from '@/components/grid/GridVx'; //AgGrid
import GridPaging from '@/components/grid/GridPaging';
import './plugins/vxValidator';
// import Highcharts from 'highcharts';

import VxDialog from './plugins/vxDialog';
import VxToast from './plugins/vxToast';
import VxForm from './plugins/vxForm';
import Request from '@/utils/request';
import InputsVX from '@/components/ui/InputsVX.vue'; // Input
import VCalendar from 'v-calendar';
import Location from '@/components/common/Location.vue'; //Location
import Title from '@/components/common/Title.vue'; //Page Title
import Messages from '@/utils/messages';
// import VueMoment from 'vue-moment';

Vue.prototype.$axios = Request;
Vue.config.productionTip = false;
Vue.use(VxDialog);
Vue.use(VxToast);
Vue.use(VxForm);
moment.locale('ko');
Vue.use(VueMoment, { moment });
Vue.use(VCalendar);
Vue.component('Title', Title);
Vue.component('Location', Location);
Vue.prototype.$messages = Messages;

Vue.component('OpenWindow', OpenWindow);
Vue.component('GridVx', GridVx);
Vue.component('InputsVX', InputsVX);
Vue.component('GridPaging', GridPaging);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
