<template>
  <div class="title">
    {{ title
    }}<span class="title-sub" v-if="useSubTitle">({{ subTitle }})</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: '',
      subTitle: '',
      useSubTitle: false,
    };
  },
  methods: {
    addHeaderShadow() {
      // document
      //   .querySelector('.contents-wrap .content-box')
      //   .addEventListener('scroll', () => {
      //     this.checkScroll();
      //   });
    },
    checkScroll() {
      // const scrollTop = document.querySelector(
      //   '.contents-wrap .content-box',
      // ).scrollTop;
      // scrollTop > 10
      //   ? this.$store.commit('ui/setHeaderShadow', true)
      //   : this.$store.commit('ui/setHeaderShadow', false);
    },
  },
  created() {
    const length = this.$route.matched.length;
    const title = this.$route.matched[length - 1].meta.title;
    const titles = title.split('(');
    if (titles.length === 1) {
      this.title = title;
      return;
    }
    this.useSubTitle = true;
    this.title = titles[0];
    this.subTitle = titles[1].slice(0, -1);
  },
  mounted() {
    this.checkScroll();
    this.addHeaderShadow();
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin: 10px 0;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
}
</style>
