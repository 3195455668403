export function getTimezoneOffset() {
  const now = new Date();
  const offsetMinutes = now.getTimezoneOffset(); // Get the timezone offset in minutes

  // Calculate the sign and hours/minutes values
  const sign = offsetMinutes <= 0 ? '+' : '-';
  const hours = Math.floor(Math.abs(offsetMinutes / 60));
  const minutes = Math.abs(offsetMinutes % 60);

  // Format the offset as a string
  const formattedOffset = `${sign}${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return formattedOffset;
}
