import Vue from 'vue';
import VueRouter from 'vue-router';
import CommonRoutes from './components/routes';

Vue.use(VueRouter);
const routes = CommonRoutes;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
