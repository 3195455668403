let vxFormPlugin = {};

vxFormPlugin.install = Vue => {
  Vue.prototype.$vxForm = {
    isValid: async function (validator, useAlert = true) {
      // console.log(validator);
      // console.log('check validator: ', await validator.validateWithInfo());
      const { isValid, errors } = await validator.validateWithInfo();
      if (isValid == false) {
        for (const key in errors) {
          if (errors[key].length) {
            if (useAlert) {
              Vue.vxAlert(errors[key][0]);
            }
            return isValid;
          }
        }
      }
      return true;
    },
  };

  // Vue.prototype.$formValid = {
  //   isValid: async function (validator, useAlert = true) {
  //     console.log('check validator: ', await validator.validateWithInfo());

  //     const { isValid, errors } = await validator.validateWithInfo();

  //     if (isValid == false) {
  //       for (const key in errors) {
  //         if (errors[key].length) {
  //           if (useAlert) {
  //             Vue.vxAlert(errors[key][0]);
  //             validator.$el.querySelector(`#${key}`)?.focus();
  //           }
  //           return isValid;
  //         }
  //       }
  //     }

  //     return;
  //   },
  // };
};
export default vxFormPlugin;
