import { jsonSafeParse } from '@/helpers/jsonUtils';

const state = {
  isLogin: jsonSafeParse(localStorage.getItem('isLogin')),
  idPwd: jsonSafeParse(localStorage.getItem('idPwd')),
  logToken: jsonSafeParse(localStorage.getItem('logToken')),
  checkUserIdPwd: jsonSafeParse(localStorage.getItem('checkUserIdPwd'), false),
  navis: jsonSafeParse(localStorage.getItem('navis')),
  clientType: jsonSafeParse(localStorage.getItem('clientType'), 'web'),
  userRole: jsonSafeParse(localStorage.getItem('userRole'), 'COMMON'),
};

const mutations = {
  setIsLogin(state, isLogin) {
    state.isLogin = isLogin;
  },
  setUserRole(state, role) {
    state.userRole = role;
  },
  setIdPassword(state, idPwd) {
    state.idPwd = idPwd;
  },
  setLogToken(state, logToken) {
    state.logToken = logToken;
  },
  setCheckUserIdPwd(state, checkUserIdPwd) {
    state.checkUserIdPwd = checkUserIdPwd;
  },
  setNavis(state, navis) {
    state.navis = navis;
  },
  setClientType(state, clientType) {
    state.clientType = clientType;
  },
};

const getters = {
  getIsLogin(state) {
    return state.isLogin;
  },
  getUserRole(state) {
    return state.userRole;
  },
  getLogState(state) {
    return state.isLogin.logState;
  },
  getUserId(state) {
    return state.isLogin.userId;
  },
  getUserNm(state) {
    return state?.isLogin?.userNm || '';
  },
  getPermission(state) {
    return state.isLogin.permission;
  },
  getIdPassword(state) {
    return state.idPwd;
  },
  getLogToken(state) {
    return state.logToken;
  },
  getCheckUserIdPwd(state) {
    return state.checkUserIdPwd;
  },
  getNavis(state) {
    return state.navis;
  },
  getClientType(state) {
    return state.clientType;
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  getters,
};
