import { jsonSafeParse } from '@/helpers/jsonUtils';

const state = {
  sideState: jsonSafeParse(localStorage.getItem('sideState'), 'small'), //wide, small
  // gridApis: null, //사이드 메뉴 접기/펴기 애니메이션시 그리드 리사이징 처리
  // headerShadow: false,
  laptopW: 1440,
  tableW: 1200,
  mobileW: 719,
  // theme: localStorage.getItem('ssos-theme') || 'theme-gray',
  // chartApis: null, //사이드 메뉴 접기/펴기 애니메이션시 차트 리사이징 처리
  // chartColor: {
  //   blue: '#5689DB',
  //   green: '#35C8B0',
  //   gray: '#cacaca',
  //   orange: '#F5A153',
  //   purple: '#b956ed',
  // },
  // chartColor: {
  //   blue: '#67b7dc',
  //   green: '#70d5c4',
  //   gray: '#dfdfdf',
  //   orange: '#edab6f',
  //   purple: '#d570ca',
  //   red: '#ec3764',
  // },
};

const mutations = {
  setSideState(state, sideState) {
    state.sideState = sideState;
  },
  // setGridApis(state, gridApis) {
  //   state.gridApis = gridApis;
  // },
  // setHeaderShadow(state, headerShadow) {
  //   state.headerShadow = headerShadow;
  // },
  // setTheme(state, theme) {
  //   state.theme = theme;
  // },
  // setChartApis(state, chartApis) {
  //   state.chartApis = chartApis;
  // },
};

const getters = {
  getSideState(state) {
    return state.sideState;
  },
  // getGridApis(state) {
  //   return state.gridApis;
  // },
  getHeaderShadow(state) {
    return state.headerShadow;
  },
  getLaptopWidth(state) {
    return state.laptopW;
  },
  getTabletWidth(state) {
    return state.tableW;
  },
  getMobileWidth(state) {
    return state.mobileW;
  },
  // getTheme(state) {
  //   return state.theme;
  // },
  // getChartColor(state) {
  //   return state.chartColor;
  // },
  // getChartApis(state) {
  //   return state.chartApis;
  // },
};

export const ui = {
  namespaced: true,
  state,
  mutations,
  getters,
};
