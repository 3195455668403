<template>
  <div class="grid-paging">
    <div class="paging-l">
      <!-- <button
        class="bnt-scroll__l"
        @click="onScrollMove('left')"
        v-if="bntMoveView"
      >
        <em class="icon"></em>
      </button> -->
      <div class="select-quantity">
        <select style="width: 150px" @change="onChangePageSize">
          <option value="10">10 개씩</option>
          <option value="20">20 개씩</option>
          <option value="30">30 개씩</option>
          <option value="50">50 개씩</option>
        </select>
      </div>
    </div>
    <div class="paging">
      <div class="prev">
        <button class="btn-first" @click="goToFirstPage">
          <span class="icon"></span>
        </button>
        <button class="btn-prev" @click="goToPrevPage">
          <span class="icon"></span>
        </button>
      </div>
      <div class="nums">
        <span
          class="btn-num"
          :class="{ active: pageNo === currentPage }"
          v-for="pageNo in pageButtons"
          :key="pageNo"
          @click="onSelectPage(pageNo)"
          >{{ pageNo }}</span
        >
        <!-- <div>Hello world</div> -->
      </div>
      <div class="next">
        <button class="btn-next" @click="goToNextPage">
          <span class="icon"></span>
        </button>
        <button class="btn-last" @click="goToLastPage">
          <span class="icon"></span>
        </button>
      </div>
    </div>
    <div class="paging-r">
      <div>
        <span style="font-size: 12px; opacity: 0.5; padding-right: 5px"
          >Total</span
        >
        <span style="font-size: 14px">{{ currentPage }}</span>
        <span style="font-size: 12px; opacity: 0.5"> / {{ totalSize }}</span>
      </div>
      <!-- <button
        class="bnt-scroll__r"
        @click="onScrollMove('right')"
        v-if="bntMoveView"
      >
        <em class="icon"></em>
      </button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageNum: {
      type: Number,
      default: 1,
    },
    breakPoint: {
      type: Number,
      default: 1200,
    },
    target: {
      type: String,
      default: 'agGrid',
    },
    totalSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      prevPage: null,
      pageButtons: [1],
      scrollX: 0,
      bntMoveView: false,
      currentPage: 1,
    };
  },
  methods: {
    onSelectPage(pageNo) {
      this.currentPage = pageNo;
      if (this.currentPage !== this.prevPage) {
        this.$emit('pageChanged', this.currentPage);
        this.prevPage = this.currentPage;
      }
      this.pageSizeCheck();
    },
    onScrollMove(direction) {
      const wholeWidth = parseInt(
        document.querySelector(
          `.${this.target} .ag-body-horizontal-scroll-container`,
        ).style.width,
        10,
      );
      const scrollWidth = document.querySelector(
        `.grid-wrapper.${this.target}`,
      ).offsetWidth;
      const scrollArea = wholeWidth - scrollWidth;
      const moveNum = wholeWidth / scrollWidth;

      // console.log(wholeWidth, '/', scrollWidth, moveNum);

      const moveX = scrollArea / Math.floor(moveNum);
      // console.log(moveX, '=', scrollArea, '/', moveNum);
      // const moveX = moveNum;

      const currentScrollX = document.querySelector(
        `.${this.target} .ag-body-horizontal-scroll-viewport`,
      ).scrollLeft;

      if (direction === 'right') {
        if (currentScrollX >= scrollArea) {
          return;
        }
        this.scrollX =
          currentScrollX + moveX > scrollArea
            ? scrollArea
            : currentScrollX + moveX;
      } else {
        if (currentScrollX <= 0) {
          return;
        }
        this.scrollX = currentScrollX - moveX < 0 ? 0 : currentScrollX - moveX;
      }

      document
        .querySelector(`.${this.target} .ag-body-horizontal-scroll-viewport`)
        .scrollTo({ top: 0, left: this.scrollX, behavior: 'smooth' });
    },
    pageSizeCheck() {
      if (window.innerWidth <= this.getMobileWidth) {
        if (this.pageNumber > 3) {
          this.pageNumber = 3;
        }
      } else {
        // console.log(this.pageNumber, this.totalSize);
        if (this.pageNumber > 10 || this.totalSize > 10) {
          this.pageNumber = 10;
        } else {
          this.pageNumber = this.totalSize;
        }
      }
      // console.log('page number: ', this.pageNumber);
      //실 페이징 처리 필요
      this.pageButtons = [];
      if (this.currentPage <= this.pageNumber / 2) {
        for (let i = 0; i < this.pageNumber; i++) {
          this.pageButtons.push(Math.ceil(i) + 1);
        }
      } else {
        this.pageButtons = [];
        if (this.totalSize - this.currentPage <= this.pageNumber / 2) {
          if (this.totalSize < 11) {
            for (
              let i = this.totalSize - this.pageNumber;
              i < this.totalSize;
              i++
            ) {
              this.pageButtons.push(Math.ceil(i) + 1);
            }
          } else {
            for (
              let i = this.totalSize - this.pageNumber;
              i < this.totalSize;
              i++
            ) {
              this.pageButtons.push(Math.ceil(i) + 1);
            }
          }
        } else {
          for (
            let i = this.currentPage - this.pageNumber / 2;
            i < this.currentPage + this.pageNumber / 2;
            i++
          ) {
            this.pageButtons.push(Math.ceil(i) + 1);
          }
        }
      }
    },
    checkGridMove() {
      if (window.innerWidth <= this.breakPoint) {
        this.bntMoveView = true;
      } else {
        this.bntMoveView = false;
      }
    },
    // toPrevPage
    goToPrevPage() {
      if (this.currentPage === 1) {
        return false;
      } else {
        this.currentPage = this.currentPage - 1;
        this.onSelectPage(this.currentPage);
      }
    },
    // toNextPage
    goToNextPage() {
      if (this.currentPage === this.totalSize) {
        return false;
      } else {
        this.currentPage = this.currentPage + 1;
        this.onSelectPage(this.currentPage);
      }
    },
    // toFirstPage
    goToFirstPage() {
      this.onSelectPage(1);
    },
    // toLastPage
    goToLastPage() {
      this.onSelectPage(this.totalSize);
    },
    onChangePageSize(event) {
      this.$emit('onPageSizeChange', event.target.value);
      // console.log(this.$emit('onPageSizeChange', event.target.value));
    },
  },
  computed: {
    getMobileWidth() {
      return this.$store.getters['ui/getMobileWidth'];
    },
  },
  created() {
    this.checkGridMove();
    window.addEventListener('resize', this.checkGridMove);
  },
  mounted() {
    this.pageSizeCheck();
    window.addEventListener('resize', this.pageSizeCheck);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkTabletMode);
    window.removeEventListener('resize', this.checkGridMove);
  },
  watch: {
    totalSize: {
      immediate: true,
      handler(value) {
        if (this.totalSize == 0) {
          this.currentPage = 0;
          this.pageButtons = [];
        } else {
          this.currentPage = 1;
          this.prevPage = null;
          this.pageNumber = this.totalSize;
          this.currentPage = 1;
          this.pageSizeCheck();
        }
      },
    },
    pageNum: {
      immediate: true,
      handler() {
        if (this.pageNum != this.currentPage) {
          this.onSelectPage(this.pageNum);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.select-quantity {
  select {
    width: 100px !important;
    border: none;
  }
}
</style>
