<template>
  <div class="toast-box" v-if="view">
    <transition-group name="slide-fade">
      <div v-for="(toast, index) in toasts" :key="index">
        <div class="toast-container" :class="toast.type">
          <div :class="toast.type" class="toast-text">{{ toast.message }}</div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 'success',
      view: false,
      message: '내용',
      toasts: [],
    };
  },
  methods: {
    deleteToast(id) {
      this.toasts.shift();
    },
  },
  mounted() {},
  watch: {
    message() {
      if (this.message == '') {
        return;
      }
      if (this.toasts.length >= 5) {
        this.toasts.pop();
      }
      const toast = {
        message: this.message,
        type: this.type,
      };
      this.toasts.push(toast);
      setTimeout(() => {
        this.deleteToast();
      }, 2500);
      this.message = '';
    },
  },
};
</script>
<style lang="scss" scoped>
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
