<template>
  <input
    ref="input"
    :type="type === 'price' ? 'tel' : type"
    @input="bindNumber"
    :value="value"
    :placeholder="placeholder"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    comma(val) {
      return String(val).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    },
    uncomma(str) {
      return String(str).replace(/[^\d]+/g, '');
    },
    bindNumber($event) {
      var value = $event.target.value;
      if (this.type === 'tel') {
        if (value.length >= 11) {
          value = value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        }
      }
      if (this.type === 'price') {
        // value = this.comma(this.uncomma(value));
        // console.log('run~~');
        var currency = +value.replace(/[^\d]/g, '').toString();
        value = Intl.NumberFormat().format(currency);
      }

      this.$emit('input', value);
      this.$emit('inputChange');
    },
    onFocus() {
      if (this.type === 'number' || this.type === 'price') {
        if (this.value == 0) {
          this.$emit('input', '');
        }
      }
      this.$emit('focus');
    },
    onBlur() {
      if (this.type === 'number' || this.type === 'price') {
        if (this.value == 0) {
          this.$emit('input', 0);
        }
      }
      this.$emit('blur');
    },
    checkPriceComma() {
      // console.log('price~~');
      let priceValue = String(this.value);
      var currency = +priceValue.replace(/[^\d]/g, '').toString();
      priceValue = Intl.NumberFormat().format(currency);
      this.$emit('input', priceValue);
    },
  },
  created() {
    if (this.type === 'price') {
      this.checkPriceComma();
    }
  },
  // watch: {
  //   value(val) {
  //     if (this.type === 'number') {
  //       const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
  //       if (reg.exec(val) !== null) this.value = val.replace(/[^0-9]/g, '');
  //       if (isNaN(parseFloat(val))) this.value = '';
  //     }
  //     if (this.type === 'price') {
  //       this.checkPriceComma();
  //     }
  //   },
  // },
  // watch: {
  //   focus() {
  //     if (this.focus) {
  //       this.$refs.input.focus();
  //     }
  //   },
  // },
};
</script>
