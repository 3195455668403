<template>
  <Transition name="fade-view" mode="out-in">
    <div class="loader-wrapper" v-if="view">
      <div class="preloader">
        <!-- <span class="preloader-icon">Preloading</span> -->
        <img
          class="img"
          src="../../assets/image/saas_h84_loading_icon.svg"
          alt="loading"
        />
        <div class="preloader-msg" v-html="setMsg"></div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  props: {
    view: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    setMsg() {
      return this.msg.msg;
    },
    setInfo() {
      return this.msg.info;
    },
  },
};
</script>
<style lang="scss">
@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
.preloader {
  img {
    animation: rotate 1s linear infinite;
  }
}
</style>
