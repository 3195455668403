<template>
  <div class="location-wrap">
    <div class="location-box">
      <ul class="location">
        <li v-for="(item, index) in location" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      location: ['홈'],
    };
  },
  created() {
    this.$route.matched.map(match => {
      this.location.push(match.meta.location);
    });
    if (this.location.length === 3 && this.location[1] === this.location[2]) {
      this.location.pop();
    }
    if (this.location.length === 4 && this.location[2] === this.location[3]) {
      this.location.pop();
    }
    if (!this.location[1] || this.location[1] == '') {
      this.location.splice(1, 1);
    }
  },
};
</script>

<style></style>
