import { jsonSafeParse } from '@/helpers/jsonUtils';

const state = {
  lv1Idx: jsonSafeParse(JSON.parse(localStorage.getItem('lv1Idx'))),
};

const mutations = {
  setLv1Idx(state, lv1Idx) {
    state.lv1Idx = lv1Idx;
  },
};

const getters = {
  getLv1Idx(state) {
    return state.lv1Idx;
  },
};

export const sidenavi = {
  namespaced: true,
  state,
  mutations,
  getters,
};
