import { store } from '@/store';
import { encrypt, decrypt } from './cryptoHelper';

export const setLogToken = token => {
  store.commit('auth/setLogToken', token);
  localStorage.setItem('logToken', JSON.stringify(token));
};

export const setIsLogin = resData => {
  store.commit('auth/setIsLogin', resData);
  localStorage.setItem('isLogin', JSON.stringify(resData));
};

export const getIsLogin = () => {
  return store.getters['auth/getIsLogin'];
};

export const getUserRole = () => {
  return store.getters['auth/getUserRole'];
};

export const getUserNm = () => {
  return store.getters['auth/getUserNm'];
};

export const setUserRole = role => {
  store.commit('auth/setUserRole', role);
  localStorage.setItem('userRole', JSON.stringify(role));
};

// export const deleteUserRole = () => {
// store.commit('auth/setUserRole', 'COMMON');
// localStorage.removeItem('isLogin');
// };

export const deleteIsLogin = () => {
  store.commit('auth/setIsLogin', null);
  localStorage.removeItem('isLogin');
  localStorage.removeItem('userRole');
  localStorage.removeItem('logToken');
  // localStorage.removeItem('sideState');
  store.commit('auth/setUserRole', 'COMMON');
};

export const setIdPassword = resData => {
  const id = resData.userId;
  const pw = resData.userPwd;
  // encoded password
  const enPw = encrypt(id, pw);
  const data = {
    id: id,
    pw: enPw,
  };
  store.commit('auth/setIdPassword', data);
  localStorage.setItem('idPwd', JSON.stringify(data));
};

export const setRowPassword = data => {
  const enPw = encrypt(data);
  console.log('AAAAA: ', enPw);
  return JSON.stringify(enPw);
};

export const getRowPassword = data => {
  const dePw = decrypt(data);
  return dePw;
};

export const getIdPassword = () => {
  let data = store.getters['auth/getIdPassword'];
  if (data) {
    const dePw = decrypt(data.id, data.pw);
    data = {
      id: data.id,
      pw: dePw,
    };
  }
  return data;
};

export const deleteLogInfo = () => {
  store.commit('auth/setIdPassword', null);
  localStorage.removeItem('idPwd');
};
