const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    component: () => import('@/views/selectParentComponent.vue'),
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/MainHomeView.vue'),
      },
    ],
  },
  {
    path: '/common',
    component: () => import('@/views/dashboard/Common.vue'),
  },
  {
    path: '/login',
    component: () => import('@/views/unAuthSubRouterView.vue'),
    children: [
      {
        path: '/login',
        component: () => import('@/views/login/LoginForm.vue'),
      },
      {
        path: '/login/forgot',
        component: () => import('@/views/login/LoginForgot.vue'),
        meta: {
          location: '아이디/비밀번호 찾기',
        },
      },
    ],
  },
  {
    path: '/enrollment',
    name: 'enrollment',
    component: () => import('@/views/selectParentComponent.vue'),
    children: [
      {
        path: '/enrollment',
        component: () => import('@/views/login/Enrollment.vue'),
        meta: {
          location: '회원가입신청',
          title: '회원가입신청',
        },
      },
      {
        path: '/enrollment/edit',
        component: () => import('@/views/login/Enrollment.vue'),
        meta: {
          auth: true,
          permission: ['MEMBERSHIP'],
          title: '회원정보수정',
          location: '회원정보수정',
        },
      },
    ],
  },
  {
    path: '/mng-change-info',
    name: 'mng-change-info',
    component: () => import('@/views/SubRouterView.vue'),
    children: [
      {
        path: '',
        component: () => import('@/views/login/EditMngInfo.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '관리자 정보 수정',
          location: '관리자 정보 수정',
        },
      },
    ],
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('@/views/unAuthSubRouterView.vue'),
    redirect: '/intro/home',
    meta: {
      auth: true,
      title: '소개',
      location: '소개',
    },
    children: [
      {
        path: '/intro/home',
        component: () => import('@/views/intro/Home.vue'),
        meta: {
          auth: true,
          title: '소개',
          location: '소개',
        },
      },
    ],
  },
  {
    path: '/service',
    name: 'sersvice',
    component: () => import('@/views/unAuthSubRouterView.vue'),
    redirect: '/service/intro',
    children: [
      {
        path: '/service/intro',
        component: () => import('@/views/service/ServiceIntro.vue'),
        meta: {
          auth: true,
          title: '사출성형해석',
          location: '사출성형해석',
        },
      },
      {
        path: '/service/mold-design',
        component: () => import('@/views/service/ServiceMoldDesign.vue'),
        meta: {
          auth: true,
          title: '금형설계',
          location: '금형설계',
        },
      },
    ],
  },
  {
    path: '/service-use',
    name: 'sersvice-use',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/service-use/service-use-mng',
    meta: {
      location: '서비스사용관리',
    },
    children: [
      {
        path: '/service-use/service-use-mng',
        component: () => import('@/views/ServiceUseMng/serviceUseMng.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '서비스 사용관리',
          location: '서비스 사용관리',
        },
      },
      {
        path: '/service-use/service-suspension-mng',
        component: () =>
          import('@/views/ServiceUseMng/serviceSuspensionMng.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '서비스중지관리',
          location: '서비스중지관리',
        },
      },
    ],
  },
  {
    path: '/settlement-mng',
    component: () => import('@/views/selectParentComponent.vue'),
    meta: {
      auth: true,
      title: '정산관리',
      location: '정산관리',
    },
    children: [
      {
        path: '/settlement-mng/check-uses',
        component: () => import('@/views/UserPaymentMng/checkUses.vue'),
        meta: {
          auth: true,
          permission: ['USER', 'MEMBERSHIP'],
          title: '이용내역 조회',
          location: '이용내역 조회',
        },
      },
      {
        path: '/settlement-mng/check-bills',
        component: () => import('@/views/UserPaymentMng/checkBill.vue'),
        meta: {
          auth: true,
          permission: ['MEMBERSHIP'],
          title: '청구내역 조회',
          location: '청구내역 조회',
        },
      },
    ],
  },
  {
    path: '/customer-support',
    name: 'customer-support',
    component: () => import('@/views/unAuthSubRouterView.vue'),
    redirect: '/customer-support/announcement',
    meta: {
      title: '고객지원',
      location: '고객지원',
    },
    children: [
      {
        path: '/customer-support/announcement',
        redirect: '/customer-support/announcement/list',
        component: () => import('@/views/subRouterLv2.vue'),
        meta: {
          auth: true,
          title: '공지사항',
          location: '공지사항',
        },
        children: [
          {
            path: '/customer-support/announcement/list',
            component: () =>
              import('@/views/CustomerSupport/announcementList.vue'),
            meta: {
              auth: true,
              title: '공지사항',
              location: '공지사항',
            },
          },
          {
            path: '/customer-support/announcement/detail',
            component: () =>
              import('@/views/CustomerSupport/announcementDetail.vue'),
            meta: {
              auth: true,
              title: '공지사항',
              location: '공지사항',
            },
          },
        ],
      },
      {
        path: '/customer-support/inquiry',
        redirect: '/customer-support/inquiry/list',
        component: () => import('@/views/subRouterLv2.vue'),
        meta: {
          auth: true,
          title: '1:1문의',
          location: '1:1문의',
        },
        children: [
          {
            path: '/customer-support/inquiry/list',
            component: () => import('@/views/CustomerSupport/inquiryList.vue'),
            meta: {
              auth: true,
              title: '1:1문의',
              location: '1:1문의',
            },
          },
          {
            path: '/customer-support/inquiry/register',
            component: () =>
              import('@/views/CustomerSupport/inquiryRegister.vue'),
            meta: {
              auth: true,
              title: '1:1문의',
              location: '1:1문의',
            },
          },
          {
            path: '/customer-support/inquiry/detail',
            component: () =>
              import('@/views/CustomerSupport/inquiryDetail.vue'),
            meta: {
              auth: true,
              title: '1:1문의',
              location: '1:1문의',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/user-mng',
    name: 'user-mng',
    component: () => import('@/views/selectParentComponent.vue'),
    redirect: '/user-mng/user-mng-list',
    meta: {
      auth: true,
      title: '이용자관리',
      location: '이용자관리',
    },
    children: [
      {
        path: '/user-mng/user-mng-list',
        component: () => import('@/views/UserMng/userInquiryList.vue'),
        meta: {
          auth: true,
          permission: ['USER', 'MEMBERSHIP'],
          title: '이용자 조회',
          location: '이용자 조회',
        },
      },
      {
        path: '/user-mng/user-register',
        component: () => import('@/views/UserMng/userRegistration.vue'),
        meta: {
          auth: true,
          permission: ['USER', 'MEMBERSHIP'],
          title: '이용자 정보',
          location: '이용자 정보',
        },
      },
    ],
  },
  {
    path: '/member-mng',
    name: 'member-mng',
    component: () => import('@/views/selectParentComponent.vue'),
    redirect: '/member-mng/member-inquiry-list',
    meta: {
      auth: true,
      title: '회원관리',
      location: '회원관리',
    },
    children: [
      {
        path: '/member-mng/member-inquiry-list',
        component: () => import('@/views/subRouterLv2.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '회원조회',
          location: '회원조회',
        },
        children: [
          {
            path: '',
            component: () => import('@/views/MemberMng/memberInquiry.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER'],
              title: '회원조회',
              location: '회원조회',
            },
          },
          {
            path: '/member-mng/member-inquiry-list/detail',
            component: () => import('@/views/MemberMng/memberChangeInfo.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER'],
              title: '회원조회',
              location: '회원조회',
            },
          },
        ],
      },
      {
        path: '/member-mng/membership-approval',
        component: () => import('@/views/MemberMng/membershipApproval.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '회원가입승인',
          location: '회원가입승인',
        },
      },
    ],
  },
  {
    path: '/miscellany',
    name: 'miscellany',
    component: () => import('@/views/selectParentComponent.vue'),
    redirect: '/miscellany/term-of-use',
    children: [
      {
        path: '/miscellany/term-of-use',
        component: () => import('@/views/Miscellany/termOfUses.vue'),
        meta: {
          auth: true,
          title: '이용약관',
          location: '이용약관',
        },
      },
      {
        path: '/miscellany/policy',
        component: () => import('@/views/Miscellany/policy.vue'),
        meta: {
          auth: true,
          title: '개인정보처리방침',
          location: '개인정보처리방침',
        },
      },
    ],
  },
  {
    path: '/user-service-mng',
    name: 'user-service-mng',
    meta: {
      location: '서비스 관리',
      title: '서비스 조회',
    },
    component: () => import('@/views/selectParentComponent.vue'),
    redirect: '/user-service-mng/service-request',
    children: [
      {
        path: '/user-service-mng/service-request',
        component: () => import('@/views/subRouterLv2.vue'),
        meta: {
          auth: true,
          permission: ['USER', 'MEMBERSHIP'],
          title: '서비스 조회',
          location: '서비스 조회',
        },
        children: [
          {
            path: '/user-service-mng/service-request',
            component: () =>
              import('@/views/UserServiceMng/serviceRequest.vue'),
            meta: {
              auth: true,
              permission: ['USER', 'MEMBERSHIP'],
              title: '서비스 조회',
              location: '서비스 조회',
            },
          },
          {
            path: '/user-service-mng/service-request/detail',
            component: () =>
              import('@/views/UserServiceMng/serviceDetails.vue'),
            meta: {
              auth: true,
              permission: ['USER', 'MEMBERSHIP'],
              title: '서비스 조회',
              location: '서비스 조회',
            },
          },
        ],
      },
      {
        path: '/user-service-mng/service-result-inquiry',
        component: () => import('@/views/subRouterLv2.vue'),
        meta: {
          auth: true,
          permission: ['USER', 'MEMBERSHIP'],
          title: '서비스 결과',
          location: '서비스 결과',
        },
        children: [
          {
            path: '/user-service-mng/service-result-inquiry',
            component: () =>
              import('@/views/UserServiceMng/serviceResultInquiry.vue'),
            meta: {
              auth: true,
              title: '서비스 결과',
              location: '서비스 결과',
            },
          },
          {
            path: '/user-service-mng/service-result-inquiry/detail',
            component: () =>
              import('@/views/UserServiceMng/serviceResultDetail.vue'),
            meta: {
              auth: true,
              title: '서비스 결과',
              location: '서비스 결과',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/service-result-register/:token',
    component: () => import('@/views/unAuthSubRouterView.vue'),
    meta: {
      auth: false,
      title: '서비스 결과',
      location: '서비스 결과',
    },
    children: [
      {
        path: '/service-result-register/:token',
        meta: {
          title: '서비스 결과',
          location: '서비스 결과',
        },
        component: () =>
          import('@/views/UserServiceMng/serviceResultRegister.vue'),
      },
    ],
  },
  {
    path: '/board-mng',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/board-mng/notice-mng',
    meta: {
      location: '게시판관리',
      title: '게시판관리',
    },
    children: [
      {
        path: '/board-mng/notice-mng',
        component: () => import('@/views/MngBoard/noticeMng.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '공지사항관리',
          location: '공지사항관리',
        },
      },
      {
        path: '/board-mng/notice-mng/register',
        component: () => import('@/views/MngBoard/noticeRegister.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '공지사항 등록',
          location: '공지사항 등록',
        },
      },
      {
        path: '/board-mng/notice-mng/detail/:id',
        component: () => import('@/views/MngBoard/noticeDetail.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '공지사항관리',
          location: '공지사항관리',
        },
      },
      {
        path: '/board-mng/inquiry-mng',
        component: () => import('@/views/MngBoard/inquiryMng.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '1:1문의 관리',
          location: '1:1문의 관리',
        },
      },
      {
        path: '/board-mng/inquiry-mng/register',
        component: () => import('@/views/MngBoard/inquiryMngRegister.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '1:1문의 관리',
          location: '1:1문의 관리',
        },
      },
    ],
  },
  {
    path: '/member-mng',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/member-mng/member-inquiry',
    meta: {
      location: '회원관리',
      title: '회원관리',
    },
    children: [
      {
        path: '/member-mng/member-inquiry',
        component: () => import('@/views/MemberMng/memberInquiry.vue'),
        meta: {
          auth: true,
          permission: ['ADMIN', 'USER'],
          title: '회원조회',
          location: '회원조회',
        },
      },
    ],
  },
  {
    path: '/sales-mng',
    component: () => import('@/views/selectParentComponent.vue'),
    redirect: '/sales-mng/sales-status',
    meta: {
      location: '매출관리',
      title: '매출관리',
    },
    children: [
      {
        path: '/sales-mng/sales-status',
        component: () => import('@/views/SalesMng/salesStatus.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '매출현황',
          location: '매출현황',
        },
      },
      {
        path: '/sales-mng/sales-status-test',
        component: () => import('@/views/SalesMng/salesStatus copy.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '매출현황',
          location: '매출현황',
        },
      },
      {
        path: '/sales-mng/settlement-processing',
        component: () => import('@/views/SalesMng/processing.vue'),
        meta: {
          auth: true,
          permission: ['MANAGER'],
          title: '정산처리',
          location: '정산처리',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/error',
  },
  {
    path: '/error',
    component: () => import('@/views/ErrorView/NotFoundView.vue'),
    meta: { auth: false, navi: true },
  },
  {
    path: '/no-permission',
    component: () => import('@/views/ErrorView/NoPermissionView.vue'),
    meta: { auth: false, navi: true },
  },
];

export default routes;
