import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from '@/store/auth.module';
import { ui } from '@/store/ui.module';
import { sidenavi } from '@/store/sidenavi.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    ui,
    sidenavi,
  },
});
