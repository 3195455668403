<template>
  <div
    class="grid-wrapper"
    :class="{ gridScroll: scrollUse }"
    v-show="gridVisible"
    style="height: 100%"
  >
    <ag-grid-vue
      style="height: 100%"
      class="ag-theme-balham"
      :gridOptions="gridOptions"
      :columnDefs="getColumns"
      :rowData="rows"
      :rowSelection="rowSelection"
      :defaultColDef="defaultColDef"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :frameworkComponents="frameworkComponents"
      :context="context"
      :suppressRowTransform="suppressRowTransform"
      @sortChanged="onSortChanged"
      @columnResized="columnResized"
      @selection-changed="onSelectionChanged"
      @rowClicked="onRowClicked"
      @cellClicked="onCellClicked"
      @first-data-rendered="onFirstDataRendered"
      :getRowHeight="getRowHeight"
      @cellValueChanged="rowValueChanged"
      :stopEditingWhenCellsLoseFocus="true"
      :enableBrowserTooltips="true"
      :tooltipHideDelay="tooltipHideDelay"
      :tooltipShowDelay="tooltipShowDelay"
      :suppressRowClickSelection="suppressRowClickSelection"
      :pinnedTopRowData="pinnedTopRowData"
      :pinnedBottomRowData="pinnedBottomRowData"
      :rowClassRules="rowClassRules"
    ></ag-grid-vue>
  </div>
</template>
<script>
import bus from '@/utils/bus';
// import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    AgGridVue,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    disabledSortable: {
      type: Boolean,
      default: true,
    },
    frameworkComponents: {
      type: Object,
      default: () => null,
    },
    context: {
      type: Object,
      default: () => {},
    },
    suppressRowTransform: {
      type: Boolean,
      default: false,
    },
    rowSelection: {
      type: String,
      default: 'multiple',
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    defaultColDef: {
      type: Object,
      default: () => {
        return {
          suppressMovable: true,
          resizable: false,
          sortable: true,
        };
      },
    },
    treeData: {
      type: Boolean,
      default: false,
    },
    autoGroupColumnDef: {
      type: Object,
      default: () => null,
    },
    groupDefaultExpanded: {
      type: Number,
      default: -1,
    },
    groupField: {
      type: String,
      default: '',
    },
    pinnedTopRowData: {
      type: Array,
      default: null,
    },
    pinnedBottomRowData: {
      type: Array,
      default: null,
    },
    headerHeight: {
      type: Number,
      default: 40,
    },
    breakPoint: {
      type: Number,
      default: 1200,
    },
    domLayout: {
      type: String,
      default: 'autoHeight',
    },
    suppressRowClickSelection: {
      type: Boolean,
      default: false,
    },
    rowHeight: {
      default: 50,
    },
    rowClassRules: null,
    reRenderRow: {
      default: false,
    },
    autoSizeCell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gridVisible: false,
      sortColumn: null,
      sortOrder: null, //1:Ascending, 2:Descending
      gridOptions: null,
      gridApi: null,
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center"><div class="loader">Loading...</div></span>',
      selectedData: null,
      getDataPath: null,
      isRowSelectable: null,
      scrollUse: false,
      // rowHeight: 38,

      tooltipShowDelay: 0,
      tooltipHideDelay: 0,
    };
  },
  methods: {
    onRowClick(event) {
      if (event.targetType === 'cell') {
        const { rowKey } = event;
        rowKey;
        this.$emit('rowClick', this.rows[rowKey]);
      }
    },
    onSortChanged(e) {
      this.sortColumn = null;
      this.sortOrder = null;
      e.columnApi.getColumnState().forEach(c => {
        if (c.sort !== null) {
          this.sortColumn = c.colId;
          // this.sortOrder = c.sort === 'asc' ? 1 : 2;
          this.sortOrder = c.sort;
          return false;
        }
      });
      this.doRefresh();
    },
    doRefresh() {
      let params = {
        orderBy: this.sortColumn,
        sort: this.sortOrder,
      };
      this.$emit('refresh', params);
    },
    camelToSnakeCase(field) {
      return field.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    },
    onSelectionChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.$emit('selectionChanged', selectedRows);
    },
    onRowClicked() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.$emit('rowClicked', selectedRows);
    },
    columnResized(event) {
      // console.log('Column Resized');
      this.$emit('columnResized', event);
    },
    onCellClicked(params) {
      // console.log('cell clicked: ', params.column.colId);
    },
    getRowSelected() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      this.selectedData = selectedNodes.map(node => node.data);
      return this.selectedData;
    },
    onDoubleClick() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.$emit('doubleClicked', selectedRows);
    },
    // eslint-disable-next-line no-unused-vars
    onFirstDataRendered(params) {
      // params.api.sizeColumnsToFit();
      this.deviceScrollCheck();
    },
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
      //   if (window.innerWidth > this.breakPoint) {
      //     this.gridApi.sizeColumnsToFit();
      //   }
    },
    autoSizeAll() {
      console.log('autoSizeAll() 실행');
      const allColumnIds = [];
      this.gridOptions.columnApi.getColumns().forEach(column => {
        allColumnIds.push(column.getId());
      });
      this.gridOptions.columnApi.autoSizeColumns(allColumnIds, false);
    },
    deviceScrollCheck() {
      // console.log('deviceScrollCheck');
      this.sizeToFit();
      // if (this.autoSizeCell) {
      //   this.scrollUse = true;
      //   return;
      // }
      // if (window.innerWidth <= this.breakPoint) {
      //   this.autoSizeAll();
      //   // this.sizeToFit();
      //   this.scrollUse = true;
      //   console.log('auto size all');
      // } else {
      //   // console.log('size to fit');
      //   // this.sizeToFit();
      //   this.scrollUse = false;
      // }
    },
    getRowHeight(params) {
      // consul && fault receipt
      let length = 1;
      if (
        (params.data && params.data.apl_tp) ||
        (params.data && params.data.app_tps) ||
        (params.data && params.data.cust_coms)
      ) {
        for (const property in params.data) {
          let stringItem = String(params.data[property]);
          if (params.data[property] && stringItem.includes('\n')) {
            if (length < stringItem.split('\n').length) {
              length = stringItem.split('\n').length;
            }
          }
        }
        let height = 34 * length;
        return height;
      }
      return this.rowHeight;
    },
    setGroupHeight(height) {
      // eslint-disable-next-line
      this.rowHeight = height;
      this.gridApi.resetRowHeights();
    },
    rowValueChanged() {
      let selectedRows = this.gridApi.getSelectedRows();
      this.$emit('rowValueChanged', selectedRows);
    },
    refreshAll() {
      // alert(3);
      // this.gridApi.refreshCells();
      this.gridApi.redrawRows();
      // this.gridApi.refreshRows();
      console.log('refreshAll');
    },
  },
  computed: {
    getColumns() {
      const disabled = this.disabledSortable;
      if (disabled === null) {
        return this.columns;
      } else {
        return this.columns.map(c => ({
          ...c,
          comparator: () => {
            return 0;
          },
        }));
      }
    },
    getSideState() {
      return this.$store.getters['ui/getSideState'];
    },
  },
  created() {
    if (this.treeData) {
      this.getDataPath = data => {
        return data[this.groupField];
      };
    }
    // this.sizeToFit();
    // setTimeout(this.sizeToFit);
    window.addEventListener('resize', this.deviceScrollCheck);
    setTimeout(() => {
      this.gridVisible = true;
    });
  },
  beforeMount() {
    this.gridOptions = {
      pagination: false,
      paginationAutoPageSize: false,
      domLayout: this.domLayout,
      headerHeight: this.headerHeight,
      // rowHeight: this.rowHeight,
      localeText: { noRowsToShow: '데이터가 없습니다.' },
      suppressContextMenu: true,
      onRowDoubleClicked: this.onDoubleClick,
      // getRowId: params => params.data.id,
      // wrapHeaderText: true,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    const gridApis = [this.gridApi];
    // this.$store.commit('ui/setGridApi', gridApis);
    // setTimeout(() => {
    //   this.deviceScrollCheck(); //데이터가 없는 경우 대비
    //   document
    //     .querySelector('.ag-body-horizontal-scroll-viewport')
    //     .addEventListener('scroll', () => {
    //       setTimeout(() => {
    //         this.deviceScrollCheck();
    //       }, 100);
    //     });
    // });
    this.sizeToFit();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.deviceScrollCheck);
  },
  watch: {
    rows() {
      setTimeout(() => {
        this.deviceScrollCheck();
      });
    },
    selectedData() {
      // console.log('선택 로우값 변경');
      this.$emit('changeSelectedData', this.selectedData);
    },
    reRenderRow() {
      if (this.reRenderRow) {
        this.refreshAll();
      }
    },
    getSideState() {
      setTimeout(() => {
        this.sizeToFit();
      }, 500);
    },
  },
};
</script>

<!-- <style>
.rag-green-outer {
  background-color: #f2abb4 !important;
}
</style> -->
