import Vue from 'vue';
import axios from 'axios';
import { store } from '@/store';
import router from '@/router';
import { deleteIsLogin } from '../utils/auth';
import { getTimezoneOffset } from './timezone';
import vxDialogPlugin from '@/plugins/vxDialog';

Vue.use(vxDialogPlugin);

const timezone = getTimezoneOffset();

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, //url = base url + request url
  timeout: 30000,
});

service.interceptors.request.use(config => {
  config.headers['Timezone'] = timezone;
  if (store.state.auth.logToken) {
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json';
    }
    config.headers.Authorization = 'Bearer ' + store.state.auth.logToken;
  }
  return config;
});

service.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          deleteIsLogin();
          router.push('/').catch(() => {});
          setTimeout(() => {
            router.push('/login').catch(() => {});
          }, 100);
          return Promise.reject(error);
        // case 500:
        //   deleteIsLogin();
        //   router
        //     .push('/')
        //     .then(() => {
        //       Vue.vxAlert('문제가 발생했습니다.');
        //     })
        //     .catch(() => {});
        //   // setTimeout(() => {
        //   //   router.push('/login').catch(() => {});
        //   // }, 100);
        //   return Promise.reject(error);
        // case 503:
        //   router.push('/').catch(() => {});
        //   deleteIsLogin();
        //   setTimeout(() => {
        //     router.push('/login').catch(() => {});
        //   }, 100);
        //   return Promise.reject(error);
        default:
          return Promise.reject(error);
      }
    }
  },
);

export default service;
