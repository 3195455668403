import Vue from 'vue';
import VueRouter from 'vue-router';
import router from '@/router/index.js';
import { getUserRole } from '@/utils/auth';

Vue.use(VueRouter);

const whiteList = ['/login', '/error', '/no-permission'];

router.beforeEach((to, from, next) => {
  // scroll to top
  document
    .getElementById('app')
    .scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // end scroll to top
  let userType = getUserRole();
  if (whiteList.includes(to.path)) {
    if (to.path == '/login' && userType != 'COMMON') {
      next('/');
    } else {
      next();
    }
    return;
  }
  let metaPermission = [];
  if (to.meta && to.meta.permission) {
    metaPermission = to.meta.permission;
  }
  if (
    metaPermission.includes(userType.toUpperCase()) ||
    metaPermission.length == 0
  ) {
    next();
  } else {
    // next('no-permission');
    next('/');
  }
});
