<template>
  <transition name="fade-view">
    <div
      class="dialog-wrapper alert"
      :class="{ confirm: type === 'confirm', warning: red }"
      v-if="view"
    >
      <use-focus-trap v-if="isFocusTrapActive" :options="{ immediate: true }">
        <div class="dialog-box">
          <span class="btn-close" @click="doClose">닫기</span>
          <div class="dialog-header" v-if="false">
            <slot name="header">{{ title }}</slot>
          </div>
          <div class="dialog-body">
            <span v-html="message"></span>
          </div>
          <div class="dialog-footer">
            <button
              type="button"
              class="btns medium secondary"
              @click="doReject"
              v-if="type == 'confirm'"
            >
              {{ rejectText }}
            </button>
            <button
              type="button"
              class="ui-button"
              @click="doResolve"
              v-if="type == 'alert'"
            >
              {{ resolveText }}
            </button>
            <button
              type="button"
              class="btns medium teriary"
              @click="doResolve"
              v-if="type == 'confirm'"
            >
              {{ resolveText }}
            </button>
          </div>
        </div>
      </use-focus-trap>
    </div>
  </transition>
</template>

<script>
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component';

export default {
  components: {
    UseFocusTrap,
  },
  data() {
    return {
      type: 'alert',
      view: false,
      title: '알림',
      message: '내용',
      resolveText: '닫기',
      rejectText: '취소',
      resolve: null,
      reject: null,
      // sytv
      red: false,
      isFocusTrapActive: false,
    };
  },
  methods: {
    doResolve() {
      this.resolve && this.resolve();
      this.CloseDialog();
    },
    doReject() {
      this.reject && this.reject();
      this.CloseDialog();
    },
    doClose() {
      this.type === 'alert' ? this.doResolve() : this.doReject();
    },
    CloseDialog() {
      this.view = false;
      this.isFocusTrapActive = false;
    },
    initialFocus() {
      return this.$refs.dialog;
    },
  },
  watch: {
    view() {
      if (this.view) {
        this.isFocusTrapActive = true;
      }
    },
  },
};
</script>
