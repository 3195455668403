import CryptoJS from 'crypto-js';

export const encrypt = (userId, plainText) => {
  if (!userId) {
    userId = '';
  }
  let secret = userId + 'saasA@1';
  try {
    return CryptoJS.AES.encrypt(plainText, secret).toString();
  } catch (e) {
    return '';
  }
};

export const decrypt = (userId, cipher) => {
  if (!userId) {
    userId = '';
  }

  let secret = userId + 'saasA@1';
  try {
    return CryptoJS.AES.decrypt(cipher, secret).toString(CryptoJS.enc.Utf8);
  } catch (e) {
    return '';
  }
};
