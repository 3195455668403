// denife messages here...
const messages = {
  info: {
    no_result_found: '검색결과가 없습니다.',
  },
  warn: {
    max_upload_count: '최대 10개 파일 업로드.',
    cf_pw_not_match: '작성한 비밀번호가 다릅니다. 다시 입력해 주세요.',
    pw_invalid:
      '비밀번호 기준에 맞지 않습니다.영문 대소문자, 숫자, 특수기호를 포함하여 8~15자로 작성해 주세요.',
    cf_cancel_membership:
      '작성된 내용이 모두 사라집니다. 회원가입을 취소하시겠습니까?',
    cf_cancel_editing:
      '작성된 내용이 모두 사라집니다. 수정을 취소하시겠습니까?',
    alert_select_row: '행을 하나 이상 선택하세요.',
  },
  error: {
    error_msg: '문제가 발생했습니다.',
    invalid_files: '잘못된 파일.',
    download_file_err: '파일을 다운로드할 수 없습니다.',
    unable_download_new_file: '새로 업로드된 파일을 다운로드할 수 없습니다.',
    file_exceed_20mb: '파일은 20MB 미만이어야 합니다.',
  },
};

export default messages;
